import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Box, Button, Grid, Typography, Select, MenuItem } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { emailValidator, passwordValidator, nameValidator } from '../util/validator';
import {connect} from 'react-redux';
import {checkServiceNameApi, signUpApi, eprocessifyAppsActivationApi, registerScopeApi} from '../services/authService';
import {checkIfUserIsRegisteredApi} from '../services/authService';
import {login} from '../actions/auth';
import { consoleToLog, capitalizeFirstLetterOfEachWord, decodeAccessToken, 
    showAllCountries, showAllStates, getServiceName, getAuthToken, checkPasswordValidity  } from '../util/AppUtil';
import { useSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getQueryParams } from '../util/urlUtil';
import { useIsMount } from '../components/useIsMount';
import { useNavigate } from 'react-router-dom';
import {setLoading} from '../actions/loading';
import {setApiBaseUrl} from '../util/AppUtil';
import {respectiveAppTitleAndSubtitle} from '../util/AppUtil';
import {getCountryApi} from '../services/authService';
import { fetchAccessToken } from '../util/AppUtil';

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        width:'100vw',
        height:'100vh',
        overflow:'auto'
    },
    onBoardingDesignStyle : {
        background: '#3D3D3D',
        height:'100vh',
        padding:'40px 48px'
    },
    logoStyle: {
        width: '220px',
        height: '49.18px',
        [theme.breakpoints.down(1400)] : {
            width: '190px',
            height: '40px',
        }
    },
    headingContainer: {
        marginTop:'70px'
    },
    headingTextStyle: {
        fontSize:'25px',
        fontWeight: '600',
        color:'#fff',
        lineHeight:'31px',
        [theme.breakpoints.down(1400)] : {
            fontSize:'21px'
        }
    },
    paragraphTextStyle: {
        marginTop:'16px',
        color:'#fff',
        fontSize:'13px',
    },
    imageContainer : {
        marginTop:'72px',
        [theme.breakpoints.down(1400)] : {
            marginTop:'52px'
        }
    },
    rightPanelStyle: {
        background:  theme.palette.primary.light,
        height:'100vh',
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    },
    greetingHeadingStyle: {
        fontSize:'20px',
        fontWeight:'600'
    },
    greetingTextStyle: {
        fontSize:'16px',
        marginTop:'16px',
        color:'#333'
    },
    innerbg: {
        border: "1px solid #dbdbdb",
        borderRadius: "10px",
        padding: "32px 48px",
        background: "#ffffff",
        height:'auto',
        // [theme.breakpoints.down("md")]: {
        //     padding: "45px 20px",
        //     }
    },
    onboardHeading: {
        fontSize : "19px",
        fontWeight: "600",
        margin:'0px auto 8px auto',
    },
    mainbg: {
        background: theme.palette.primary.light,
        height:'100vh',
        overflow:'auto'
    },
    circularProgress: {
        marginLeft: 0,
        marginRight: theme.spacing(2),
        color: 'white'
    },
    imageStyle: {
        marginLeft:'16px',
        [theme.breakpoints.down(1400)] : {
            width:'75%'
        }
    },
    enableStyle:{
        width:"100%",
        height:"2.5rem",
        padding:"2px",
    },
    disableStyle:{
        width:"100%",
        height:"2.5rem",
        padding:"2px",
        color:"#888"
    },
}));

const SignupPage = (props) => {
        const classes = useStyles(props);
        const { enqueueSnackbar } = useSnackbar();
        const navigate = useNavigate();

        const isMount = useIsMount();
        const [fullname, setFullName] = useState('');
        const [lastName, setLastName] = useState('');
        const [email, setEmail] = useState('');
        const [password, setPassword] = useState('');
        const [confirmPassword, setConfirmPassword] = useState('');

        const [fnInvalid, setFnInvalid] = useState(false);
        const [emailInvalid, setEmailInvalid] = useState(false);
        const [passwordInvalid, setPasswordInvalid] = useState(false);
        const [passwordError, setPasswordError] = useState("");
        const [confirmPasswordInvalid, setConfirmPasswordInvalid] = useState(false);
        const [serviceName, setServiceName] = useState('');
        const [selectedCountry, setSelectedCountry] = useState('none');
        const [selectedState, setSelectedState] = useState('none');
        
        const [loading, setLoading] = useState(false);
        const [nameError, setNameError] = useState('')
        const [registerEmail, setRegisterEmail]= useState();
        const [serviceNameValidated, setserviceNameValidated] = useState(true);
        const [loading1, setLoading1] = useState(false);
        const [authToken, setAuthToken] = useState(undefined);
        const [invitedUser, setInvitedUser] = useState(false);
        const [registeredUser, setRegiseteredUser] = useState(false);
        const [queryParams, setQueryParams] = useState(getQueryParams(document.location.search));
        const [appTitleAndSubtitle, setAppTitleAndSubtitle] = useState(); 

        const fetchCountry = () => {
            getCountryApi(process.env.REACT_APP_COUNTRY_API_TOKEN)
                .then((response) => {
                    const res = response.data;
                    consoleToLog('Response getCountryApi', res);

                    setSelectedCountry(res.country);
                })
                .catch((e) => {
                    consoleToLog('Error getCountryApi', e);
                })
        }
        
        
        useEffect(()=> {
        document.title = 'eProcessify | Register';
        consoleToLog('query_params********', queryParams);
        //fetchCountry();
        
        setLoading1(true);
        if(queryParams.service === undefined && queryParams.email === undefined) {
            navigate('/apps');
            setLoading1(false);
        
        } else { 

            if(queryParams.email !== undefined){
                setEmail(queryParams.email);
                setRegisterEmail(queryParams.email);
                setServiceName(queryParams.service);
                setAuthToken(queryParams.auth_token);
                setInvitedUser(queryParams.invited);
                setRegiseteredUser(queryParams.registered);
                navigate('/register');
            } else {
                let service_name = getServiceName(window.location.href);
                setServiceName(service_name);
            }
        }
        }, []);

        useEffect(() => {
            if(!isMount) {
                if(serviceName !== '') {
                    const fetchToken = async () => {
                        try {
                        const data = await fetchAccessToken(serviceName);
                        if(data) {
                            consoleToLog('Response authenticateApi', data);
                            setLoading1(false);
                            setserviceNameValidated(true);
                            const titleSubtitle = respectiveAppTitleAndSubtitle(serviceName);
                            setAppTitleAndSubtitle(titleSubtitle);

                            const {access_token, redirect_url, invalid_service, scope} = data;
                            const user_scope = scope?.join();
                            if(access_token) {
                                if(redirect_url) {
                                    window.open(`${redirect_url}\?scope=${user_scope}`, '_self');
                                } else if(!redirect_url && !invalid_service) {
                                    navigate(`/not-registered?service=${serviceName}`);
                                } else if(!redirect_url && invalid_service) {
                                    navigate('/apps');
                                }
                            } 

                        } 
                        } catch (error) {
                            consoleToLog("error fetchAccessToken", error);
                            setLoading1(false);
                        }
                    };
                
                    fetchToken();
                }
            }
    }, [serviceName]);

        const onSubmitClick = () => {
        const isFullNameValid = nameValidator(fullname);
        if (!fullname) {
            setFnInvalid(true);
            setNameError('*Full Name is Required');
            return;
        }

        if(!isFullNameValid) {
            setFnInvalid(true);
            setNameError('*Name is not valid! Please enter Alphabets only');
            return
        }

        if (!email) {
            //console.log('email', email)
            setEmailInvalid(true);
            return;
        }
        
        const isEmailValid = emailValidator(email);
        if (!isEmailValid) {
            setEmailInvalid(true);
            return;
        }

        if(selectedCountry === 'none' || selectedCountry === '' || selectedCountry === undefined) {
            enqueueSnackbar('Please Select the Country', {
                variant: 'error'
            });
            return;
        }
    
        // if(selectedCountry !== 'IN') {
        //     enqueueSnackbar('Please Select India as Country', {
        //         variant: 'error'
        //     });
        //     return;
        // }
    
        if (!password) {
            setPasswordInvalid(true);
            setPasswordError('* Password Required');
            return;
        }

        const isPasswordMessage = checkPasswordValidity(password)//passwordValidator(password);
        if (isPasswordMessage) {
            setPasswordInvalid(true);
            setPasswordError(isPasswordMessage);
            return;
        }

        if (password !== confirmPassword) {
            setConfirmPasswordInvalid(true);
            return;
        }

        setLoading(true);
        callSignUpApi(email, fullname, password)
    }

    const callSignUpApi = (email, fullname, password) => {
        const invited = queryParams.invited && queryParams.invited === "true" ? true : false;

        signUpApi(serviceName, capitalizeFirstLetterOfEachWord(fullname), email, selectedCountry, password, invited)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response signUpApi: ", res);
                
                enqueueSnackbar(res.message, { 
                    variant: 'success',
                });

                if(invited) {
                    const paramsObj = getQueryParams(res.activation_url);
                    if(paramsObj) {
                        callActivationApi(res.access_token, paramsObj);
                    }
                } else {
                    navigate(`/activate?email=${email}&service=${serviceName}&dont_call_api=${true}`);
                    setLoading(false);
                }

            })
            .catch((e) => {
                consoleToLog("signUpApi error", e);
                setLoading(false);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, { 
                        variant: 'error',
                    });
                    return false;
                }
            });
    }

    const callActivationApi = (accessToken, paramsObj) => {
        const service = queryParams.service;
        const email = queryParams.email;
        const firstname = paramsObj.firstname ? paramsObj.firstname: '';
        const lastname = paramsObj.lastname ? paramsObj.lastname : '';
        const country = paramsObj.country;

        const apiBaseUrl = setApiBaseUrl(service);
        const access_token = `Bearer ${accessToken}`;
        //console.log('auth_token********', auth_token1);

        eprocessifyAppsActivationApi(access_token, apiBaseUrl, service, email, firstname, lastname, country, queryParams.role, 
            queryParams.invited, queryParams.registered, queryParams.entity_id) 
            .then((response) => {
                const res = response.data;
                consoleToLog('Response mainAppInvoiceAppActivationApi: ', res);
                enqueueSnackbar(res.message, {variant:'success'});

                //setAccountActivated(true);
                registerScope(res.redirect_url);
            })
            .catch((e) => {
                consoleToLog('Error mainAppInvoiceAppActivationApi: ', e.response);
                setLoading(false);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant:'error'});
                  // setInvalidAuthToken(true);
                    return;
                }
            })
    }

    const registerScope = (redirect_url) => {
        registerScopeApi(queryParams.service)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response registerScopeApi: ', res);
                setLoading(false);
                const scope = res.scope.join();
                window.open(`${redirect_url}&first_login=${res.first_login}&scope=${scope}`, '_self');
                //window.open(`https://localhost:8181/?service=${queryParams.service}&first_login=${res.first_login}&scope=${scope}`, '_self');
                props.setLoading(true);
            })
            .catch((e) => {
                consoleToLog('Error registerScopeApi: ', e.response);
                setLoading(false);
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })
    }

    
    return (
            <div className={classes.mainContainer}>
                {loading1 ?
                <Grid item container justifyContent='center' alignItems='center' style={{marginTop:'18px'}}>
                    <CircularProgress size={28}/>
                </Grid>
                :
                <>
                {serviceNameValidated ?
                <Grid item container>
                    <Grid item md={4} className={classes.onBoardingDesignStyle}>
                            <Grid item container direction='column'>
                                <Grid item md={12}>
                                    <img src='/images/eProcessify_logo_name_white.png' className={classes.logoStyle}/>
                                </Grid>

                                <Grid item md={12} className={classes.headingContainer}>
                                    <Typography className={classes.headingTextStyle}>
                                        {appTitleAndSubtitle?.title}
                                    </Typography>

                                    <Typography className={classes.paragraphTextStyle}>
                                        {appTitleAndSubtitle?.subtitle}
                                    </Typography>
                                </Grid>

                                <Grid item md={12} className={classes.imageContainer}>
                                    <img src='/images/on_borboarding_image.svg' className={classes.imageStyle}/>
                                </Grid>
                            </Grid>
                    </Grid>

                    <Grid item md={8} className={classes.rightPanelStyle}>
                        <Grid item md={7}>

                            <Grid item md={12} style={{marginTop:'32px'}}>
                            
                                <Grid item md={12} className={classes.innerbg}>
                                    <Typography className={classes.onboardHeading}>
                                        Register to eProcessify
                                    </Typography>

                                    <Typography variant="subtitle1">Enter your details to create your account</Typography>

                                    <Grid container direction="column" >
                                        <Grid item xs={12} style={{marginTop: "15px"}}>
                                            <TextField
                                                fullWidth
                                                id="fullname"
                                                label="*Full Name"
                                                type="text"
                                                variant="outlined"
                                                margin="dense"
                                                InputLabelProps={{style: {fontSize: 14}}}
                                                error={fnInvalid}
                                                helperText={fnInvalid ? nameError : ''}
                                                onChange={e => { 
                                                    setFullName(e.target.value);
                                                    setFnInvalid(false);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} style={{marginTop: "15px"}}>
                                            <TextField
                                                fullWidth
                                                id="email"
                                                label="*Email"
                                                type="text"
                                                variant="outlined"
                                                margin="dense"
                                                InputLabelProps={{style: {fontSize: 14}}}
                                                error={emailInvalid}
                                                value={email}
                                                helperText={emailInvalid ? "* Email is invalid!" : ''}
                                                onChange={e => { 
                                                        setEmail(e.target.value);
                                                        setEmailInvalid(false);
                                                }}
                                                disabled={registerEmail}
                                            />
                                        </Grid>
                                        <Grid item md={12} lg={12} style={{marginTop: "15px"}}>
                                            <Select 
                                                className={selectedCountry === 'none' ? classes.disableStyle : classes.enableStyle}
                                                value={selectedCountry}
                                                onChange={(e) => setSelectedCountry(e.target.value)}
                                                variant="outlined"
                                                placeholder='Select Country'
                                                autoWidth
                                                //disabled={disabled}
                                            >
                                            {
                                                selectedCountry === 'none' && <MenuItem value='none'>*Select Country</MenuItem>
                                            } 
                                            {showAllCountries()}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={12} style={{marginTop: "15px"}}>
                                            <TextField
                                                fullWidth
                                                id="password"
                                                label="*Password"
                                                type="password"
                                                margin="dense"
                                                variant="outlined"
                                                InputLabelProps={{style: {fontSize: 14}}}
                                                onChange={e => { 
                                                    setPassword(e.target.value);
                                                    setPasswordInvalid(false);
                                                }}
                                                error={passwordInvalid}
                                                helperText={passwordInvalid ? passwordError : ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12} style={{marginTop: "15px"}}>
                                            <TextField
                                                fullWidth
                                                id="confirm-password"
                                                label="*Confirm Password"
                                                type="password"
                                                margin="dense"
                                                variant="outlined"
                                                InputLabelProps={{style: {fontSize: 14}}}
                                                onChange={e => { 
                                                    setConfirmPassword(e.target.value);
                                                    setConfirmPasswordInvalid(false);
                                                }}
                                                error={confirmPasswordInvalid}
                                                helperText={confirmPasswordInvalid ? "* Passwords are not matching!" : ''}
                                                onKeyPress={(ev) => {
                                                // consoleToLog(`Pressed keyCode ${ev.key}`);
                                                    if (ev.key === 'Enter') {
                                                        onSubmitClick();
                                                        ev.preventDefault();
                                                    }
                                                }}
                                            />
                                        </Grid>
                                    </Grid>


                                    <Grid item md={12} style={{margin: '24px 0px 16px 0px'}}>  
                                        <Button variant="contained" color="primary" 
                                            fullWidth
                                            onClick={()=>onSubmitClick()}
                                            >
                                            {loading && <CircularProgress size={20} style={{color:'#fff', marginRight:'8px'}}/>}
                                            Register
                                        </Button>
                                        <Typography variant="subtitle1" style={{marginTop: "12px"}}>By Registering, you agree to the <span style={{color: "blue", cursor: "pointer"}} onClick={()=>{window.open("https://eprocessify.com/terms-of-service", "_blank")}}>Terms of Service</span> and <span style={{color: "blue", cursor: "pointer"}} onClick={()=>{window.open("https://eprocessify.com/privacy-policy", "_blank")}}>Privacy Policy</span></Typography>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                :
                <Grid item container justifyContent='center' alignItems='center'
                    style={{backgroundColor: '#f7f7f7 !important', height:'100%'}}
                    className={classes.bgImage}>
                    <img src='/images/404-not-found.png' />
                </Grid>
                }
                </>}
            </div>
        )
}

const mapStateToProps = (state) => ({
    auth: state.auth
});

const mapDispatchToProps = (dispatch) => ({
    login: (auth) => dispatch(login(auth)),
    setLoading: (isLoading) => dispatch(setLoading(isLoading))
});

export default connect(mapStateToProps, mapDispatchToProps)(SignupPage);